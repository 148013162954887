import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom"
import { CircularProgress, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { validateLogin } from '../../../utilities/auth';
import * as authActions from "../../../redux/actions/auth"


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        height: "100%",
        width: '100%',
    },
    innerImage: {
        width: '100%',
        height: "100%",
        objectFit: "cover",
        objectPosition: "70%"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const [email, setEmail] = useState('');

    
    const [errors, setErrors] = useState({})
    const [imageLoader, setImageLoader] = useState(true);

    const { isLoading } = useSelector(state => state.auth)

    const handleForgotPassword = () => {
        if (email) {
            dispatch(authActions.forgotPassword(email)).then(() => {
                navigate('/auth/reset-confirmation', { state: { email } });
            }).catch(() => {
                setErrors(true);
            });
        } else {
            setErrors(true);
        }
    };

    const onChangeHandler = ({ target: { value = '', name = '' } }) => {
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (isLoading) return

        const { errors, isValid } = validateLogin(user)
        setErrors(errors);

        if (isValid) {
            dispatch(authActions.login(user)).then((res) => {
                navigate("/dashboard")
            }).catch(() => { })
        }
    }

    const LoaderStyle = {
        height: "100%",
        overflow: "hidden"
    }

    return (
        <div className="login-background-contianer">
            <Grid container component="main" className={`${classes.root} login-container`}>

            <Grid item xs={12} sm={12} md={12} lg={12} className="login-container-item">
                <Paper style={{ height: "100vh" }}>
                    <Grid container className="" style={{ height: "100%" }}>
                        <Grid item xs={12} sm={12} md={6} style={{ display: "flex" }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}  className="form-container-item">
                                        <div className={classes.paper}>
                                            <div className="login-logo-div">
                                                <img src="../assets/images/logo-login.svg" alt="ANKAR" />
                                            </div>
                                            <form className={`${classes.form} login-form`} onSubmit={onSubmit} noValidate>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"

                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    error={errors && errors.email ? !!errors.email : !!''}
                                                    value={user.email}
                                                    onChange={onChangeHandler}
                                                    autoComplete="email"
                                                    autoFocus
                                                />
                                                <TextField
                                                    variant="outlined"

                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    error={errors && errors.password ? !!errors.password : !!''}
                                                    value={user.password}
                                                    onChange={onChangeHandler}
                                                    autoComplete="current-password"
                                                />

                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={onSubmit}
                                                >
                                                    {isLoading ? <CircularProgress size={25} style={{ color: "white" }} /> : 'Login'}
                                                </Button>
                                            </form>
                                  
                                        </div>
                                    </Grid>
                                </Grid>
              
                            </Grid>
                            <Hidden only={['xs', 'sm']}>
                                <Grid item md={6} className={classes.image}>
                                    <img src="../assets/images/first-page.jpg" alt='' onLoad={() => setImageLoader(false)} className={classes.innerImage} />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
        </div>
    );
}