import { isEmpty } from "lodash";

export function validateProjectData(data = {}) {
    const errors = {};

    if (!data.name) {
        errors.name = "Name is required.";
    }

    if (!data.app) {
        errors.app = "App is required.";
    }

    if(!data.image){
        errors.image = "Image is required.";
    }
    if(!data.webUrl){
        errors.webUrl = "Website Url is required.";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}