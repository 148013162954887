import { apiGet, apiPost, apiPut, apiDelete } from '../../utils';

export function getAllAdminAPI(data) {
    return apiGet('api/v1/admin',data);
}

export function addUserAPI(data ) {
    return apiPost('api/v1/admin/', data);
}

export function deleteUserAPI(id) {
    return apiDelete(`api/v1/admin/${id}`)
}

export function updateUserAPI(id,data) {
    return apiPut(`api/v1/admin/${id}`,data);
  } 
  