export function validateUserData(user, isEdit) {
  const errors = {};

  if (!user.name) {
    errors.name = "Name is required!";
  }

  if (!user.email) {
    errors.email = "Email is required!";
  } else if (!isValidEmail(user.email)) {
    errors.email = "Invalid email address!";
  }

  if (!isEdit && !user.password) {
    errors.password = "Password is required!";
  } else if (!isEdit && user.password.length < 6) {
    errors.password = "Password should be at least 6 characters long!";
  }

  if (!isEdit && (!user._id || user._id.trim() === "")) {
    errors.projectId = "Project ID is required!";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
}

function isValidEmail(email) {
  // Use a regular expression to validate the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
