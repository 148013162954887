import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@material-ui/icons/Search';
import queryString from 'query-string';

import { Button, Grid, InputAdornment, } from '@mui/material';
import _, { filter } from 'lodash';
import * as IntegrationsActions from '../redux/actions/integration';
import * as commonActions from '../redux/actions/common'
import CommonLoading from "../components/common/CommonLoading"
import CustomTable from '../components/common/CustomTable';
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';
import CustomInput from '../components/common/CustomInput';


const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Logo',
    key: 'logo',
  },

  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Created at',
    key: 'createdAt',
  },
  {
    name: 'Updated at',
    key: 'updatedAt',
  },

  {
    name: 'Actions',
    key: 'actions'
  },

]

export default function IntegrationPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { allIntegrations : { data =[], total = 0},  isLoading } = useSelector(state => state.integrations, shallowEqual);
  console.log(useSelector(state=>state.integrations))
  const [isActiveTab, setIsActiveTab] = useState(true)
  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search })
  }

  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search })
  }



  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);


  console.log(total, 'total items')
  console.log('Current Page:', currentPage);
  console.log('Total Pages:', totalPages);

  const handlePrevPage = (prevPage) => {

    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };


  const hitRequest = (filters = {}) => {
    dispatch(IntegrationsActions.getAllIntegrations({ ...filters, }))
  }

  const handleConfirmation = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(item) },
      cancel: () => { }
    }, "ConfirmationModal"))

  }

  const handleEdit = (item) => {
    console.log('edit clicked')
    dispatch(commonActions.openCustomModalDialog({
      title: "Edit",
      data: item
    }, "AddIntegrationModal"))
  }

  const handleDelete = (item) => {
    dispatch(IntegrationsActions.deleteIntegrations(item._id)).then(() => {
      dispatch(IntegrationsActions.getAllIntegrations())
    }).catch(() => { })
  }

  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {

      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value, cartStatus: isActiveTab }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }


  const parseRowData = useMemo(() => {
    return data?.map(((integrations, idx) => {
      const createdAt = new Date(integrations.createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false 
      });
      const updatedAt = new Date(integrations.updatedAt).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
  
      const logo = integrations?.logo.startsWith('https') && !integrations?.logo.startsWith('https://https')
        ? integrations?.logo
        : integrations?.logo + '?w=150&h=150&fit=crop&auto=format';
  
      return {
        ...integrations,
        s_no: filters.skip + (idx + 1),
        name: integrations?.name.charAt(0).toUpperCase() + integrations?.name.slice(1),
        logo: (
          <img
            src={logo}
            srcSet={`${integrations?.logo}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
            alt={'NO image yet'}
            onError={(e) => {
              e.target.src = '/assets/dummy-post.jpg'
            }}
            loading='lazy'
            style={{ width: '60px', height: '30px' }}
          />
        ),
        createdAt,
        updatedAt,
        actions: (
          <Box>
            <EditIcon onClick={() => handleEdit(integrations)} />
            <DeleteIcon onClick={() => handleConfirmation(integrations)} />
          </Box>
        )
      }
    }))
  }, [data]);
  

  useEffect(() => {
    if (data.length === 0) {
      setCurrentPage(0); 
    }
  }, [data]);

  const handleReset = () => {
    const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
    setFilters({
      ...filters,
      skip: 0,
      page: 0,
    })
      setCurrentPage(skip / limit)
};

  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }

      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT,  sortBy: 'active' })
      return;
    }
    hitRequest({ ...newFilters, searchTerm: searchT, })

  }, [])

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    navigate({
      pathname: '/dashboard/integrations',
      search: location.search
    })
  }, [filters, search, isActiveTab])



  return (
    <>
      <Helmet>
        <title>  Integrations | Super Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className=''>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Integration</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0  jc-content-end">
             {/* <div className='search-input'>
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
            <div className="manage-top-tabs">
              <Button variant="contained" onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddIntegrationModal'))}>
                Add Integration
              </Button>
            </div>
          </Grid>

        </Grid>
        {
          isLoading ? <CommonLoading isLoading={isLoading} /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
            onReset={handleReset}
          />
        }


      </Grid>
    </>
  );
}