import { apiGet, apiPost, apiPut, apiDelete } from '../../utils';

export function getAllIntegrationsAPI(data) {
    const { limit = 10, skip = 0, searchTerm = '',} = data || {}
    const newData = { limit, skip, searchTerm, }
    return apiGet(`api/v1/integration`,newData)
}



export function createIntegrationAPI(data) {
    return apiPost(`api/v1/integration`,data)
}

export function deleteIntegrationsAPI(id) {
    return apiDelete(`api/v1/integration/${id}`);
}

export function uploadImageAPI(data){
    return apiPost(`api/v1/integration/upload`,data);
}

export function updateIntegrationAPI(id,data){
    return apiPost(`api/v1/integration/${id}`,data);
}