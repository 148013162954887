import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Button, Grid} from '@mui/material';
import _ from 'lodash';
import * as productsActions from "../redux/actions/products";
import * as commonActions from '../redux/actions/common'
import CommonLoading from "../components/common/CommonLoading"
import CustomTable from '../components/common/CustomTable';
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';


const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Favicon',
    key: 'favicon',
  },
  {
    name: 'Logo',
    key: 'image',
  },

  {
    name: 'Project Name',
    key: 'name',
  },
  {
    name: 'sub-domain',
    key: 'app',
  },

  {
    name: 'Actions',
    key: 'actions'
  },

]

export default function ProjectPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const { allProjects : { data =[], total = 0 }, isLoading } = useSelector(state => state?.products, shallowEqual);
  console.log(useSelector(state => state?.products))
  const [isActiveTab, setIsActiveTab] = useState(true)
  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 500), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })


  useEffect(() => {
    if (data.length === 0) {
      setCurrentPage(0); 
    }
  }, [data]);

  const handleReset = () => {
    const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
    setFilters({
      ...filters,
      skip: 0,
      page: 0,
    })
      setCurrentPage(skip / limit)
};

  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search })
  }

  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e,10), page: 0, skip: 0 }
    setFilters(newFilters)
    hitRequest({ ...newFilters, searchTerm: search })
  }

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);

  console.log(total, 'total')

  const handlePrevPage = () => {
    if (currentPage === 0) return;
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };

  const hitRequest = (filters = {}) => {
    dispatch(productsActions.getAllProjects({ ...filters, sortBy: 'active' }))
  } 

  const handleConfirmation = (id) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(id) },
      cancel: () => { }
    }, "ConfirmationModal"))

  }

  const handleEdit = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Edit",
      data: item
    }, "AddProjectModal"))
  }

  const handleDelete = (item) => {
    productsActions.deleteProject(item._id).then(() => {
      dispatch(productsActions.getAllProjects())
    }).catch(() => { })
  }

  // const handleChange = (e) => {
  //   const { value } = e.target
  //   const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value }
  //   setSearch(value);
  //   setFilters({ limit: 10, skip: 0, page: 0 })
  //   setCurrentPage(0)
  //   debouncedSave(newFilters);
  // }

  const parseRowData = useMemo(() => {
    return data?.map(((project, idx) => {
      const image = project?.image.startsWith('https') && !project?.image.startsWith('https://https')
        ? project?.image
        : project?.image + '?w=150&h=150&fit=crop&auto=format';

      return {
        ...project,
        s_no: filters.skip + (idx + 1),
        image: (
          <img
            className="flowertype-list"
            src={image}
            srcSet={`${image}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
            alt={project?.app}
            loading="lazy"
          />
        ),
        favicon: (
          <img
            src={project?.favicon}
            srcSet={project?.favicon}
            alt={project?.app}
            loading="lazy"
            className='favicon-img'
          />
        ),
        name: project?.name.charAt(0).toUpperCase() + project?.name.slice(1),
        actions: (
          <Box>
            <EditIcon onClick={() => handleEdit(project)} />
            <DeleteIcon onClick={() => handleConfirmation(project)} />
          </Box>
        )
      }
    }))
  }, [data])



  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }

      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT, sortBy: 'active' })
      return;
    }
    hitRequest(filters);


  }, [])

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    navigate({
      pathname: '/dashboard/projects',
      search: location.search
    })
  }, [filters, search, isActiveTab])



  return (
    <>
      <Helmet>
        <title> Project | Super Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className=''>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Project</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 jc-content-end">
            {/* <div className='search-input'>
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
            <div className="manage-top-tabs ml-3">
              <Button variant="contained" onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddProjectModal'))}>
                Add Project
              </Button>
            </div>
          </Grid>

        </Grid>
        {
          isLoading ? <CommonLoading isLoading={isLoading} /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
            onReset={handleReset}
          />
        }


      </Grid>
    </>
  );
}