import { toast } from "react-toastify"
import {  loginAPI } from "../api/auth";

export const SET_USER = 'SET_USER';

export function setUser(user) {
    return dispatch => {
        dispatch({
            type: SET_USER,
            user
        })
    }
}


export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export function login(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_LOGIN
        })
        return new Promise((resolve, reject) => {
            loginAPI(data)
                .then((res) => {
                  
                    dispatch({
                        type: LOGIN_SUCCESSFULL,
                        user: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: LOGIN_FAILED
                    })
                    toast.error(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}

export const LOGOUT_USER = 'LOGOUT_USER';

export function logoutUser(user) {
    return dispatch => {
        dispatch({
            type: LOGOUT_USER,
            user
        })
    }
}


export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export function forgotPassword(user) {
    return dispatch => {
        dispatch({
            type: FORGOT_PASSWORD,
            user
        })
    }
}




