import { isEmpty } from "lodash";

export function validateIntegrationData(data = {}) {
    const errors = {};

    if (!data.name) {
        errors.name = "Name is required";
    }

    if (!data.logo){
        errors.logo = "Logo is required";
    }

    if(isEmpty(data.metadata)){
        errors.metadata = "Metakey is required";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}