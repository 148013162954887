import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProjectPage from './pages/ProjectPage';
import PrivateRoutes from './components/privateRoutes';
import { getData } from './utils';
import AdminPage from './pages/UserPage';
import IntegrationPage from './pages/IntegrationPage';

// ----------------------------------------------------------------------

export default function Router() {
  const loggedInToken = getData('accessToken')
  const routes = useRoutes([
    {
      path: '/dashboard',
      element:<PrivateRoutes>
         <DashboardLayout />
      </PrivateRoutes>,
      children: [
        { element: <Navigate to="/dashboard/projects" />, index: true },
        { path: 'projects' ,element: <ProjectPage/>},
        { path: 'create_projects' ,element: <ProjectPage/>},
        { path: 'admin',element: <AdminPage/>},
        { path: 'integrations', element: <IntegrationPage/>}
   
      ],
    },
    {
      path: 'login',
      element: !!loggedInToken ? <Navigate to='/dashboard/projects' /> :<LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/projects" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
