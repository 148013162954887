import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid,IconButton, Input } from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import * as actions from "../../redux/actions/products";
import * as commonActions from "../../redux/actions/common";
import CustomInput from '../common/CustomInput';
import { validateProjectData } from '../../utilities/validations/project';
import { ChromePicker } from 'react-color';

const initialProject = {
  name: "",
  app: "",
  projectId: "",
  image: "",
  favicon: "",
  webUrl:""
};

export default function AddProjectModal(props) {
  const dispatch = useDispatch();

  const { modalData } = useSelector(state => state.common);
  const [project, setProject] = useState({ ...initialProject });
  const [projectId, setProjectId] = useState('');
  const [initialImage, setInitialImage] = useState('');
  const [errors, setErrors] = useState({ ...initialProject });
  const [imageFile, setImageFile] = useState(null);
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [faviconPreview, setFaviconPreview] = useState('');
  const { title = "Add", data: editData = {} } = modalData;
  const { _id } = editData;
  const [faviconFile, setFaviconFile] = useState(null);
  const [faviconFilename, setFaviconFilename] = useState('');
  const faviconFileInputRef = useRef(null);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  const [themeColorsState, setThemeColorsState] = useState({});
  const [editThemeColors, setEditThemeColors] = useState({});


  const toggleColorPicker = (theme) => {
    setThemeColorsState(prevState => ({
      ...prevState,
      [theme]: {
        open: !prevState[theme]?.open,
        color: prevState[theme]?.color || '#FFFFFF'
      }
    }));
  };

  const handleColorChange = (theme, color) => {
    if (title === 'Edit') {
      setEditThemeColors(prevEditThemeColors => ({
        ...prevEditThemeColors,
        [theme]: color
      }));
    }

    setThemeColorsState(prevState => ({
      ...prevState,
      [theme]: {
        ...prevState[theme],
        color
      }
    }));
  };



  const onFaviconButtonClick = () => {
  faviconFileInputRef.current.click();
  };

  const [showThemeColors, setShowThemeColors] = useState(false);
  const [selectedThemes, setSelectedThemes] = useState([]);

  const themeColors = [
    'graph-primary',
    'graph-secondary',
    'primary',
    'primary-light',
    'primary-theme',
    'primary-theme-dark',
    'secondary',
    'secondary-color-light',
    'secondary-light'
  ];

  const toggleTheme = (theme) => {
    if (selectedThemes.includes(theme)) {
      setSelectedThemes(selectedThemes.filter(t => t !== theme));
    } else {
      setSelectedThemes([...selectedThemes, theme]);
    }
  };

  const toggleThemeColors = () => {
    setShowThemeColors(prevShowThemeColors => !prevShowThemeColors);
    setSelectedThemes([]); // Clear selected themes when toggling

    if (title === 'Edit') {
      // Use editThemeColors when editing
      if (!showThemeColors) {
        setThemeColorsState(editThemeColors);
      } else {
        // Clear themeColorsState when closing theme colors section
        setThemeColorsState({});
      }
    }
  };



  useEffect(() => {
    if (title === 'Edit') {
      const currentEditThemeColors = {};
      themeColors.forEach(theme => {
        if (editData.themeColors && editData.themeColors[theme]) {
          currentEditThemeColors[theme] = editData.themeColors[theme];
        } else {
          currentEditThemeColors[theme] = '#FFFFFF';
        }
      });
      setProject(prevProject => ({
        ...prevProject,
        name: editData.name || '',
        app: editData.app || '',
        image: editData.image || '',
        webUrl:editData.webUrl || '',
        favicon:editData.favicon ||''
      }));
      setEditThemeColors(currentEditThemeColors);
      setInitialImage(editData.image || '');
      setImagePreview(editData.image || '');
      setFaviconPreview(editData.favicon || '')
    }
  }, [editData]);

  const handleImageChange =  (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        setImageUrl(dataURL);
        setImagePreview(dataURL);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append('image', file);

      dispatch(actions.uploadProjectImage(formData))
        .then((res) => {
          const filename = res?.data?.filename;
          setUploadedFilename(filename);
          setProject((prevState) => ({
            ...prevState,
            image: filename,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            image: '',
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fileInputRef = useRef(null);

  const onButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFaviconChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFaviconFile(file);
        setImageFile(file);
        
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = reader.result;
          setImageUrl(dataURL);
          setFaviconPreview(dataURL)
        };
        reader.readAsDataURL(file);
  
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      dispatch(actions.uploadProjectImage(formData))
      .then((res) => {
        const filename = res?.data?.filename;
        console.log("response from favicon image :- ",filename)
        // if (res.data && res.data.filename) {
        //   setFaviconFilename(res.data.filename);
        //   setProject((prevProject) => ({ ...prevProject, favicon: res.data.filename }));
        // }
          setFaviconFilename(filename);
        setProject((prevState) => ({
          ...prevState,
          favicon: filename,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          favicon: '',
        }));
      })
      .catch((error) => {
        console.log(error);
      });
      // });
    }
  };


  const onChangehandler = e => {
    const { name = '', value = '' } = e.target || {};
    setProject(prevProject => ({ ...prevProject, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  // const onAddProject = () => {
  //   const { isValid, errors } = validateProjectData(project);

  //   if (isValid) {
  //     const { projectId, favicon, ...projectData } = project;
  //     console.log("project ---- >>> ", project)

  //     const selectedColors = {};
  //     themeColors.forEach(theme => {
  //       if (themeColorsState[theme]?.color) {
  //         selectedColors[theme] = themeColorsState[theme].color;
  //       }
  //     });
  //        projectData.themeColors = selectedColors;

  //     if (title === 'Edit') {
  //       if (project.image === initialImage) {
  //         delete projectData.image;
  //       }
  //       projectData.themeColors = editThemeColors;

  //       if (faviconFilename) {
  //         projectData.favicon = faviconFilename;
  //       }

  //       dispatch(actions.updateProject(_id, projectData)).then(res => {
  //         dispatch(commonActions.closeCustomModalDialog());
  //         dispatch(actions.getAllProjects());
  //       });
  //     } else {
  //       dispatch(actions.addProject(projectData)).then(res => {
  //         console.log("res",res, "projectData projectData projectData  ",projectData)
  //         dispatch(commonActions.closeCustomModalDialog());
  //         dispatch(actions.getAllProjects());
  //       }).catch((error) => {})
  //     }
  //   } else {
  //     setErrors({ ...errors });
  //     console.log("errors", errors);
  //   }
  // }

  const onAddProject = () => {
    const { isValid, errors } = validateProjectData(project);
  
    if (isValid) {
      const { projectId, favicon, ...projectData } = project;
  
      const selectedColors = {};
      themeColors.forEach(theme => {
        if (themeColorsState[theme]?.color) {
          selectedColors[theme] = themeColorsState[theme].color;
        }
      });
      projectData.themeColors = selectedColors;
  
      if (title === 'Edit') {
        if (project.image === initialImage) {
          delete projectData.image;
        }
        projectData.themeColors = editThemeColors;
  
        if (faviconFilename) {
          projectData.favicon = faviconFilename;
        }
  
        dispatch(actions.updateProject(_id, projectData)).then(res => {
          dispatch(commonActions.closeCustomModalDialog());
          dispatch(actions.getAllProjects());
        });
      } else {
        if (faviconFilename) {
          projectData.favicon = faviconFilename;
        }
  
        dispatch(actions.addProject(projectData)).then(res => {
          dispatch(commonActions.closeCustomModalDialog());
          dispatch(actions.getAllProjects());
        }).catch((error) => {})
      }
    } else {
      setErrors({ ...errors });
      console.log("errors", errors);
    }
  }
  


  return (
    <Grid container component="main" className="add-zipcode-modal-container mt-60">
      <Grid item xs={12} className="header-modal">
        <h3>{title} Project</h3>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='p-0'>
          <CustomInput
            name="name"
            value={project?.name || ""}
            onChange={onChangehandler}
            label="Name"
            className="w-100"
            margin="normal"
            error={errors?.name}
          />
        </Grid>
        <Grid item xs={12} sm={6} className='p-0'>
          <CustomInput
            name="app"
            value={project?.app || ""}
            onChange={onChangehandler}
            label="Sub-domain Name"
            className="w-100"
            margin="normal"
            error={errors?.app}
          />
        </Grid>
        <Grid item xs={12} sm={6} className='p-0'>
          <CustomInput
            name="webUrl"
            value={project?.webUrl || ""}
            onChange={onChangehandler}
            label="Website Url"
            className="w-100"
            margin="normal"
            error={errors?.webUrl}
          />
        </Grid>
        <Grid item xs={12} className="">
          <Grid container spacing={2}>

          <Grid item xs={6} md={6} className="add-button">
              <Button className='w-100' variant="contained" color="primary" onClick={onFaviconButtonClick}>
                Upload FavIcon
              </Button>
              
              <input
                type="file"
                ref={faviconFileInputRef}
                style={{ display: 'none' }}
                onChange={handleFaviconChange}
                />
                {!faviconPreview && errors?.image && <p className="error">{errors?.image}</p>}
            {faviconPreview && (
              <Grid item xs={12} className="d-flex  jc-ctr align-center">
                <img src={faviconPreview} alt="Preview"  className='preview-img maxpre-img' />
              </Grid>
            )}
            </Grid>
            <Grid item xs={6} md={6} className="add-button">
              <Button className='w-100' variant="contained" color="primary" onClick={onButtonClick}>
                Upload Logo
              </Button>
              
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
                />
                {!imagePreview && errors?.image && <p className="error">{errors?.image}</p>}
            {imagePreview && (
              <Grid item xs={12} className="d-flex jc-ctr align-center">
                <img src={imagePreview} alt="Preview"  className='preview-img maxpre-img' />
              </Grid>
            )}
            </Grid>


            </Grid>
            {title === 'Add' ? (
  <Grid item xs={12} className="add-button mt-2">
    <Button variant="contained" color="primary" onClick={toggleThemeColors} className='w-100' >
      {showThemeColors ? 'Close' : 'Add Theme Colors'}
    </Button>
  </Grid>
) : (
  <Grid item xs={12} className="add-button mt-2">
    <Button variant="contained" color="primary" onClick={toggleThemeColors} className='w-100'>
      {showThemeColors ? 'Close' : 'Edit Theme Colors'}
    </Button>
  </Grid>
)}

{showThemeColors && (
  <Grid spacing={2} item container className="theme-colors-container">
    {themeColors.map((theme, index) => (
    <Grid item xs={6} sm={4}>
        <div key={index} className="theme-checkbox">
          <label htmlFor={theme}>
            <input
              type="checkbox"
              id={theme}
              checked={selectedThemes.includes(theme)}
              onChange={() => toggleTheme(theme)}
            />
            {theme}
          </label>
          {selectedThemes.includes(theme) && (
            <div className="color-picker-container">
               
              <div className="color-input-container">
                  <Input
                    type="text"
                    value={title === 'Edit' ? editThemeColors[theme] || '' : themeColorsState[theme]?.color || '#FFFFFF'}
                    onChange={(e) => handleColorChange(theme, e.target.value)}
                  />

                  <IconButton className='color-picker' onClick={() => toggleColorPicker(theme)}>
                    <ColorLensIcon />
                  </IconButton>
              </div>
              
              {themeColorsState[theme]?.open && (
                <div className="color-picker">
                  <ChromePicker color={themeColorsState[theme].color} onChange={(color) => handleColorChange(theme, color.hex)} />
                </div>
              )}
            </div>
          )}
        </div>
    </Grid>
    ))}
  </Grid>
)}
      </Grid>

        <Grid item xs={12} className="d-flex jc-ctr add-button">
          <Button onClick={onAddProject} variant="contained" color="primary">Submit</Button>
        </Grid>


      </Grid>
    </Grid>
  );
}
