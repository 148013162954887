import { GET_ALL_INTEGRATIONS,GET_ALL_INTEGRATIONS_SUCCESS,GET_ALL_INTEGRATIONS_FAILURE,
        CREATE_INTERGRATIONS,CREATE_INTERGRATIONS_SUCCESS,CREATE_INTERGRATIONS_FAILURE,
        DELETE_INTERGRATIONS,DELETE_INTERGRATIONS_SUCCESS,DELETE_INTERGRATIONS_FAILURE,
        UPLOAD_INTERGRATIONS,UPLOAD_INTERGRATIONS_SUCCESS,UPLOAD_INTERGRATIONS_FAILED,
        UPDATE_INTEGRATION,UPDATE_INTEGRATION_SUCCESS,UPDATE_INTEGRATION_FAILED } from "../actions/integration";   


const initialState = {
    isLoading:false,
    allIntegrations:{},
}


export default function integrations(state = {...initialState}, action={}) {
    switch (action.type) {

        case GET_ALL_INTEGRATIONS: 
            return { ...state, isLoading: true }
        
        case GET_ALL_INTEGRATIONS_SUCCESS: 
        console.log('GET_ALL_INTEGRATIONS_SUCCESS action:', action.data);
            return { ...state, isLoading: false, allIntegrations:{...action.data} }
        
        case GET_ALL_INTEGRATIONS_FAILURE: 
            return { ...state, isLoading: false }
        
        case CREATE_INTERGRATIONS: 
            return { ...state, isLoading: true }
        
        case CREATE_INTERGRATIONS_SUCCESS: 
            return { ...state, isLoading: false }
        
        case CREATE_INTERGRATIONS_FAILURE: 
            return { ...state, isLoading: false }
        
        case DELETE_INTERGRATIONS: 
            return { ...state, isLoading: true }
        
        case DELETE_INTERGRATIONS_SUCCESS: 
            return { ...state, isLoading: false }
        
        case DELETE_INTERGRATIONS_FAILURE: 
            return { ...state, isLoading: false }

        case UPLOAD_INTERGRATIONS: 
            return { ...state, isLoading: true }
        
        case UPLOAD_INTERGRATIONS_SUCCESS: 
            return { ...state, isLoading: false }
        
        case UPLOAD_INTERGRATIONS_FAILED: 
            return { ...state, isLoading: false }
        
        case UPDATE_INTEGRATION: 
            return { ...state, isLoading: true }
        
        case UPDATE_INTEGRATION_SUCCESS: 
            return { ...state, isLoading: false }
        
        case UPDATE_INTEGRATION_FAILED: 
            return { ...state, isLoading: false }
        default: {
            return state
        }
    }
}