import { AccessTokenId, AccessTokenName, APP_NAME, isSuperAdmin } from '../../constants';
import {

    LOGIN_FAILED,
    LOGIN_SUCCESSFULL,
    LOGOUT_USER,
    REQUEST_LOGIN,
    SET_USER,
} from '../actions/auth';
import { defaultColor, getUserData, isLoggedIn, removeData, setData } from '../../utils';

const initialState = {
    user: getUserData() || {},
    isLoading: false,
    isLoggedIn: !isLoggedIn(),
    userPhone: "",
    lastSyncDate: ""
}

export default function auth(state = { ...initialState }, action = {}) {
    switch (action.type) {

        case SET_USER:
            return { ...state, user: action.user, isLoggedIn: true }


        case REQUEST_LOGIN:
            return { ...state, isLoading: true }

        case LOGIN_SUCCESSFULL: {
            // const  { projectData: { app, themeColors , image  } } = action?.user?.data;
            const logoImage = '../assets/images/logo.svg' ;
            const appColors = defaultColor;

            setData('themeColors', appColors)
            setData('logoImage',logoImage)
            const superAdmin = action?.user?.data.access_token || false
            setData(AccessTokenName, action?.user?.data)
            // setData(APP_NAME, )
            setData(AccessTokenId, action?.user?.access_token)
            setData(isSuperAdmin, superAdmin)
            return { ...state, user: action.user, isLoggedIn: true, isLoading: false }
        }


        case LOGIN_FAILED:
            return { ...state, isLoading: false, isLoggedIn: false }



        case LOGOUT_USER:
            removeData(AccessTokenId)
            removeData(AccessTokenName)
            removeData(isSuperAdmin)
            removeData("adminLastSync")
            removeData('accessToken')
            return { ...state, isLoggedIn: false }

        default:
            return state
    }
}