import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from './auth';
import products from './products';
import common from './common';
import users from "./user"
import integrations from './integrations';



const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    products,
    common,
    users,
    integrations,
   
});

export default rootReducer;