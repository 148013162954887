import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Grid, Button, TextField, InputAdornment } from '@mui/material';
import _, { filter } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import * as user from "../redux/actions/user";
import CommonLoading from "../components/common/CommonLoading"
import * as commonActions from '../redux/actions/common'
import * as productsActions from "../redux/actions/products";
import { getData } from '../utils';
import CustomTable from '../components/common/CustomTable';
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';
import CustomInput from '../components/common/CustomInput';


const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Project Name',
    key: 'app',
  },
  {
    name: "Email",
    key: 'email'
  },
  {
    name: 'Created At',
    key: 'createdAt',
  },
  {
    name: 'Actions',
    key: 'actions'
  },



]

export default function AdminPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const { allAdmins: {data=[], total = 0}, isLoading } = useSelector(state => state.users, shallowEqual)
  console.log(useSelector(state => state.users))
  const [isActiveTab, setIsActiveTab] = useState(true)
  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
 setFilters({ ...filters, skip, page,  })
 hitRequest({ ...filters, skip, page, searchTerm: search })

   }
  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, })
  }

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);


  const handlePrevPage = (prevPage) => {
    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };



  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {
      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }

  // const apiUserData = () => {
  //   dispatch(user.getAllAdminData({ email: allAdminList[0]?.email }))
  // };
  // useEffect(() => apiUserData(), []);

  const hitRequest = (filters = {}) => {
    dispatch(user.getAllAdmin({ ...filters, sortBy: 'active' }))
    // dispatch(productsActions.getAllProjects({ ...filters, sortBy: 'active' }))
  }


  const handleConfirmation = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(item) },
      cancel: () => { }
    }, "ConfirmationModal"))
  }



  const handleEdit = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Edit",
      data: item
    }, "AddUserModal"))
  }


  const handleDelete = (item) => {
    dispatch(user.deleteUser(item._id)).then(() => {
      dispatch(user.getAllAdmin())
    }).catch(() => { })
  }

  const parseRowData = useMemo(() => {
    return data?.map((item, idx) => {
      console.log("item",item)
      return {
        ...item,
        s_no: filters.skip + (idx + 1),
        app:item?.projectId?.app,
        createdAt: item.createdAt ? new Date(item.createdAt).toLocaleDateString() : "-",
        actions: (
          <Box>
            <EditIcon onClick={() => handleEdit(item)} />
            {!item.isSuperAdmin && <DeleteIcon onClick={() => handleConfirmation(item)} />}
          </Box>
        ),
      };
    }) || [];
  }, [data]);

  const handleReset = () => {
    const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
    setFilters({
      ...filters,
      skip: 0,
      page: 0,
    })
      setCurrentPage(skip / limit)
};


  useEffect(() => {
    let newFilters = {}
    let searchT = ''

    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true, cartType = "" } = queryString.parse(location.search);
      searchT = searchTerm

      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page),
      }
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT, cartType })
      return;
    }
    hitRequest({ ...newFilters, searchTerm: searchT, cartType: '' })

  }, [])

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    navigate({
      pathname: '/dashboard/admin',
      search: location.search
    })
  }, [filters, search, isActiveTab])


  return (
    <>
      <Helmet>
        <title> Admin | Super Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
          <Grid container className=''>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Admin</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 jc-content-end">
            {/* <div className='search-input'>
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon/>
                    </InputAdornment>
                  ),
                  // endAdornment: search ? (
                  //   <InputAdornment position="end" className="cur-pointer">
                  //     <ClearIcon onClick={onClickClearInputField} />
                  //   </InputAdornment>
                  // ) : null,
                }}
              />
            </div> */}
            <div className="manage-top-tabs ml-3">
            <Button variant='contained' onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddUserModal'))}>Add Admin</Button>
            </div>
          </Grid>

        </Grid>
          {
          isLoading ? <CommonLoading isLoading={isLoading} /> :<CustomTable
          tableHeading={columns}
          rowsData={parseRowData}
          currentPage={currentPage}
          totalPages={totalPages}
          skip={filters.skip}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          limit={filters.limit}
          onChangePagination={onChangePagination}
          onReset={handleReset}
        />
        }

      </Grid>
    </>
  );
}