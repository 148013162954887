
import {
    GET_ALL_ADMIN,
    GET_ALL_ADMIN_SUCCESS,
    GET_ALL_ADMIN_FAILED,
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAILED,
    DELETE_ADMIN,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAILED,
    UPDATE_ADMIN,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_FAILED
} from "../actions/user"


const initialState = {
    isLoading: false,
    allAdmins:{},
}
   
export default function user(state = { ...initialState }, action = {}) {
    switch (action.type) {

        case GET_ALL_ADMIN: 
            return { ...state, isLoading: true }
        
        case GET_ALL_ADMIN_SUCCESS: 
            return { ...state, isLoading: false, allAdmins:{...action.data} }
        
        case GET_ALL_ADMIN_FAILED: 
            return { ...state, isLoading: false }
        
        case ADD_ADMIN: 
            return { ...state, isLoading: true }
        
        case ADD_ADMIN_SUCCESS: 
            return { ...state, isLoading: false }
        
        case ADD_ADMIN_FAILED: 
            return { ...state, isLoading: false }
        
        case DELETE_ADMIN: 
            return { ...state, isLoading: true }
        
        case DELETE_ADMIN_SUCCESS: 
            return { ...state, isLoading: false }
        
        case DELETE_ADMIN_FAILED: 
            return { ...state, isLoading: false }

        case UPDATE_ADMIN: 
            return { ...state, isLoading: true }
        
        case UPDATE_ADMIN_SUCCESS: 
            return { ...state, isLoading: false }
        
        case UPDATE_ADMIN_FAILED: 
            return { ...state, isLoading: false }

        default: {
            return state
        }
    }
}