import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {  Button,  FormControlLabel,  Grid,  Switch,  TextField,  } from '@mui/material';
import CustomInput from '../../components/common/CustomInput';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as commonActions from '../../redux/actions/common';
import * as integrationsActions from '../../redux/actions/integration';
import { validateIntegrationData } from '../../utilities/validations/integrations';
import { useSelector } from 'react-redux';

const initialState = {
  name: '',
  logo:"",
  metadata: [],
};

export default function AddIntegrationModal(props) {
  const dispatch = useDispatch();
  const { modalData } = useSelector(state => state.common);
  const [integrationData, setIntegrationData] = useState(initialState);
  const [errors,setErrors] = useState({...integrationData});
  const [initialImage, setInitialImage] = useState('');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [metadataKeys, setMetadataKeys] = useState([]);
  const { title = "Add", data: editData = {} } = modalData;
  const { _id } = editData;
  const [ isUpcoming, setIsUpcoming ] = useState(false);


  const handleUpcomingStatus = () => {
    setIsUpcoming((prev) =>{
    console.log('Previous upcoming value:', prev);
     return !prev;
    })
  }

useEffect(() => {
  if (title === 'Edit') {
    setIntegrationData(prevIntegrationData => ({
      ...prevIntegrationData,
      name: editData.name || '',
      logo: editData.logo || '',
      metadata: editData.metadata || [],
    }));
    setIsUpcoming(editData.isUpcoming || false);
    setInitialImage(editData.logo || '');
    setImagePreview(editData.logo || '');

    const existingMetadataKeys = editData.metadata.map(item => Object.keys(item)[0]);
    setMetadataKeys(existingMetadataKeys);
  }
}, [title, editData]);


  const addMetadataKey = () => {
    setMetadataKeys([...metadataKeys, '']);
  };

  const removeMetadataKey = (index) => {
    const updatedKeys = [...metadataKeys];
    updatedKeys.splice(index, 1);
    setMetadataKeys(updatedKeys);
  };
  // const onMetadataKeyChange = (e, index) => {
  //   const { value } = e.target;

  //   setMetadataKeys((prevMetaDataKeys) => {
  //     const updatedKeys = [...prevMetaDataKeys];
  //     updatedKeys[index]=
  //   })

    // const updatedKeys = [...metadataKeys];
    // updatedKeys[index] = value.trim();
    // setMetadataKeys(updatedKeys);
  // };

  const onMetadataKeyChange = (e, index) => {
    const { value } = e.target;
  
    setMetadataKeys((prevMetadataKeys) => {
      const updatedKeys = [...prevMetadataKeys];
      updatedKeys[index] = value.trim();
      return updatedKeys;
    });
  
    setIntegrationData((prevData) => ({
      ...prevData,
      metadata: metadataKeys.map((key) => ({
        [key]: prevData[key] || '',
      })),
    }));
  };


  const fileInputRef = useRef(null);

  const onImageChangeHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        setImageUrl(dataURL);
        setImagePreview(dataURL);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append('logo', file);

      dispatch(integrationsActions.uploadImage(formData))
        .then((res) => {
          const filename = res?.data?.filename;
          setUploadedFilename(filename);
          setIntegrationData((prevState) => ({
            ...prevState,
            logo: filename,
          }));

          setErrors((prevErrors) => ({
            ...prevErrors,
            logo: '',
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onButtonClick = () => {
    fileInputRef.current.click();
  };


  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let updatedData = {
      ...integrationData,
      [name]: value,
    };
  
    setIntegrationData(updatedData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const onIntegrationSubmitHandler = () => {
    const { isValid, errors } = validateIntegrationData(integrationData);
    console.log(integrationData,'integrationData')
    console.log(metadataKeys)

    if (isValid) {

      console.log('in')

      let submitData = {
        name: integrationData.name,
        logo: uploadedFilename,
        metadata: metadataKeys.map((key) => ({
          [key]: integrationData[key] || '',
          
        })),
        isUpcoming,
      };

      console.log(submitData, 'submi dataa')

      const metadataObject = metadataKeys.reduce((obj, key) => {
        obj[key] = '';
        return obj;
      }, {});

      submitData = {
        ...submitData,
        logo: uploadedFilename,
        metadata: metadataKeys.map((key) => ({
          [key]: integrationData[key] || '',
        })),
      };

      if (title === 'Edit') {

        if (!uploadedFilename) {
          const filename = editData.logo ? editData.logo.split('/').pop() : '';
          submitData.logo = filename;
        }
        if (integrationData?.logo) {
          delete integrationData.logo;
        }

        dispatch(integrationsActions.updateIntegration(_id, submitData)).then((res) => {
          dispatch(commonActions.closeCustomModalDialog());
          dispatch(integrationsActions.getAllIntegrations());
        });
      } else {
        submitData.logo = uploadedFilename;

        dispatch(integrationsActions.createIntegrations(submitData)).then((res) => {
          setIntegrationData(initialState);
          setImageFile(null);
          dispatch(commonActions.closeCustomModalDialog());
          dispatch(integrationsActions.getAllIntegrations());
        });
      }
    } else {
      setErrors(errors);
      console.log("errors", errors);
    }
  };

  return (
    <Grid container component="main" className="add-zipcode-modal-container mt-60">

<Grid item xs={12} className="header-modal">
        <h3>{title} Integration</h3>
      </Grid>
        <Grid item xs={12} sm={12} className='p-0'>
          <CustomInput
            name="name"
            value={integrationData?.name}
            onChange={onChangeHandler}
            label="Name"
            className="w-100"
            margin="normal"
          />
          {errors?.name && <p className="error">{errors?.name}</p>}
        </Grid>

        <Grid item xs={12} className="add-button">
          <Button variant="contained" color="primary" onClick={onButtonClick}>
            Upload Image
          </Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onImageChangeHandler}
          />
           {errors?.logo && <p className="error">{errors?.logo}</p>}
        </Grid>


        <FormControlLabel control={<Switch checked={isUpcoming} />} label="Upcoming" value={isUpcoming}  onChange={handleUpcomingStatus}/>
        
        
        {imagePreview && (
          <Grid item xs={12} className="d-flex jc-left preview-img-list">
            <img src={imagePreview} alt="Preview" className='preview-img' />
          </Grid>
        )}

      {title === 'Add' && (<>
          <Grid item xs={12} className="d-flex jc-ctr add-button">
            <TextField
              label="Metadata Key 1 "
              value={metadataKeys[0]}
              onChange={(e) => onMetadataKeyChange(e, 0)}
              fullWidth
            />
             {
              metadataKeys.length >= 2 ?
            <div className="remove-icon color-red cur-pointer d-flex jc-ctr add-button">
              <RemoveCircleIcon onClick={() => removeMetadataKey(0)} />
            </div>
            : ""
             }
          </Grid>
            {errors?.metadata && <p className="error">{errors?.metadata}</p>}           

            </>)}

      {metadataKeys.slice(title === 'Add' ? 1 : 0).map((key, index) => (<>
          <Grid item xs={12} key={index} className="d-flex jc-ctr add-button">
            <TextField
              label={`Metadata Key ${index + (title === 'Add' ? 2 : 1)}`}
              value={key}
              onChange={(e) => onMetadataKeyChange(e, index + (title === 'Add' ? 1 : 0))}
              fullWidth
            />
            {
              metadataKeys.length >= 2 ?
            <div className="remove-icon color-red cur-pointer d-flex jc-ctr add-button ">
              <RemoveCircleIcon onClick={() => removeMetadataKey(index + (title === 'Add' ? 1 : 0))} />
            </div>
            : ""
            }
          </Grid>
          {errors?.metadata && <p className="error">{errors?.metadata}</p>}

     </> ))}
         <Grid item xs={12} >
          {title === 'Edit' && (
            <div className="add-more-fields text-right" onClick={addMetadataKey}>Add new metadata key</div>
          )}
        </Grid>
        <Grid item xs={12} >
          {title === 'Add' && (
            <div className="add-more-fields text-right" onClick={addMetadataKey}>Add new metadata key</div>
          )}
        </Grid>

        <Grid item xs={12} className="d-flex jc-ctr add-button">
          <Button onClick={onIntegrationSubmitHandler} variant="contained" color="primary" >Submit</Button>
        </Grid>
    </Grid>
  );
}


//klaviyo