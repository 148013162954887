import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Select, MenuItem, InputLabel ,FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { validateUserData } from '../../utilities/validations/user';
import * as actions from "../../redux/actions/user";
import * as productsActions from "../../redux/actions/products";
import * as commonActions from "../../redux/actions/common";
import { getData } from '../../utils';
import CustomInput from '../common/CustomInput';
import CustomCheckbox from '../common/CustomCheckBox'

const initialUser = {
  name: "",
  email: "",
  password: "",
  confimPassword: "",
  projectName: "",
  _id: "",
  permissions:{}
};
const adminPermissions = [
  'dashboard',
  'products',
  'categories',
  'promotions',
  'orders',
  'brands',
  'deliveryHours',
  'notifications',
  'customers',
  'integrations'
]

function UserModal(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  
  const { modalData } = useSelector(state => state.common);
  const { adminLoader = false } = useSelector(state => state.users);
  const { title = "Add", data: editData = {} } = modalData;
  const { allProjects:{data=[]}} = useSelector(state => state.products);
  const [user, setUser] = useState({ ...initialUser, projects: [] });
  const [errors, setErrors] = useState({ ...initialUser });
  const isEditdata = !_.isEmpty(editData);
  const { adminData } = JSON.parse(getData('ankar-admin')) || {};
  const [selectedPermissions, setSelectedPermissions ] = useState({})
  const { _id } = editData;

  useEffect(() => {
    dispatch(productsActions.getAllProjects());
  }, [dispatch]);

useEffect(() => {
  if (title === 'Edit') {
    setUser(prevUser => ({
      ...prevUser,
      name: editData.name || '',
      email: editData.email || '',
      projectName: editData.app  || '',
      permissions: editData.permissions  || {}
    }));
    setSelectedPermissions(editData.permissions  || {});
  }
}, [editData]);

  const onChangehandler = (e) => {
    const { name = '', value = '' } = e.target || {};
    setUser({ ...user, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const onProjectChange = (event) => {
    const selectedProject = data.find((project) => project.app === event.target.value);
    setUser((prevUser) => ({
      ...prevUser,
      projectName: event.target.value,
      _id: selectedProject ? selectedProject._id : '',
      projectId: selectedProject ? selectedProject.projectId : '' 
    }));
  };
    
  const checkboxHandler = (e) => { 
    const { name = '', value = '', checked =false } = e.target || {};

    setSelectedPermissions({...selectedPermissions, [name]: checked})
    setUser((prevUser) => ({
      ...prevUser,
      ['permissions']: {
        ...selectedPermissions,
        [name]: checked},
    }));

  };
  
  
  const addUser = () => {
    const { errors, isValid } = validateUserData(user, title === 'Edit');
    setErrors(errors);
    
    const { name, email, password, projectName, projectId, permissions} = user;
    const selectedProject = data.find((project) => project.app === projectName);
    const userData = {
      name,
      email,
      password,
      projectId: isEditdata ? projectId : selectedProject ? selectedProject.projectId : '',
      permissions
    };
  
    if (isValid) {
      if (isEditdata) {
        dispatch(actions.updateUser(_id, userData,password)).then(() => {
          dispatch(actions.getAllAdmin());
          dispatch(commonActions.closeCustomModalDialog({}, "closeAddUserModal"));
        }).catch(() => {});
      } else {
        if (title !== 'Edit') {
          userData.password = password;
        }
        userData.projectId = selectedProject ? selectedProject._id : '';
        dispatch(actions.addUser(userData)).then((res) => {
          dispatch(commonActions.closeCustomModalDialog());
          window.location.reload();  
        });
      }
    }
  };
  
  return (
    <Grid container component="main" className={`${classes.root} add-zipcode-modal-container mt-60`}>
      <Grid item xs={12} className="header-modal">
        <h3 className='mt-0'>{title} Admin</h3>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='p-0'>
          <CustomInput
            name="name"
            value={user.name || ""}
            onChange={onChangehandler}
            label="Name"
            className="w-100"
            margin="normal"
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6} className='p-0'>
          <CustomInput
            // disabled={isEditdata}
            name="email"
            value={user.email || ""}
            onChange={onChangehandler}
            label="Email"
            className="w-100"
            margin="normal"
            error={errors.email}
          />
        </Grid>
  {/* {title !== 'Edit' && ( */}
        <Grid item xs={12} sm={6} className='p-0'>
    <CustomInput
      type="password"
      name="password"
      value={user.password || ""}
      onChange={onChangehandler}
      label={!isEditdata ? "Password" : "New Password"}
      className="w-100 m-0"
      margin="normal"
      error={errors.password}
    />
</Grid>
  {/* )} */}
  {/* {title !== 'Edit' && ( */}
<Grid item xs={12} sm={6} className='p-0'>
    <CustomInput
      type="password"
      name="confimPassword"
      value={user.confimPassword || ""}
      onChange={onChangehandler}
      label="Confirm Password"
      className="w-100 m-0"
      error={errors.confimPassword}
    />
</Grid>
  {/* )} */}
    {title === 'Add' && (
    <Grid item xs={12}  className='p-0'>
    <FormControl fullWidth variant="outlined" error={!!errors.projectId}>
      <InputLabel id="demo-simple-select-label">Project Name</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select-helper"
      value={user?.projectName}
      onChange={onProjectChange}
      displayEmpty
      variant="outlined"
      label="Project Name"
      // renderValue={(selected) => {
      //   if (!selected) return <InputLabel id="demo-simple-select-label">Project Name</InputLabel>;
      //   return selected.charAt(0).toUpperCase() + selected.slice(1);
      // }}
    >
      {data?.map((project) => (
        <MenuItem key={project.app} value={project.app}>
          {project.app.charAt(0).toUpperCase() + project.app.slice(1)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  

        </Grid>
            )}
            
        <Grid item xs={12} >
          <InputLabel className='mt-0' id="demo-simple-select-label">Admin Menu Permissions </InputLabel>
        </Grid>

        <Grid spacing={2} item container className="permission-container">

            {adminPermissions.map((permission, index) => (
              <Grid item xs={6} sm={4}>
                <CustomCheckbox
                    type ="checkbox"
                    id = {permission}
                    name={permission}
                    value={permission}
                    onChange={checkboxHandler}
                    checked={selectedPermissions[permission] == true}
                    label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                  />
            </Grid>
            ))}
        </Grid>


        <Grid item xs={12} className="d-flex jc-ctr add-button">
          <Button
            onClick={addUser}
            variant="contained"
            color="primary"
            className='m-0'
          >
            {adminLoader ? <CircularProgress size={22} style={{ color: "white" }} /> : !isEditdata ? 'Submit' : "Update"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default UserModal;
