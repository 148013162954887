import { toast } from 'react-toastify';
import {
    addCategoryAPI,
    getAllBlazeCategoryAPI,
    getAllCategoryAPI,
    getAllProductsAPI,
    getInfoAndEffectAPI,
    getSingleProductDetailsAPI,
    updateCategoriesAPI,
    updateSingleProductAPI,
    deleteCategoryAPI,
    getAllBrandsByProductAPI,
    addBrandToBmwAPI,
    getAllCategoryImagesAPI,
    updateBrandFromBmwAPI,
    deleteBrandFromBmwAPI,
    deleteProjectAPI,
    getAllProjectsAPI,
    addProjectAPI,
    updateProjectAPI,
    uploadProjectImageAPI

} from "../api/products";


export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESSFULL = 'GET_ALL_PRODUCTS_SUCCESSFULL';
export const GET_ALL_PRODUCTS_FAILED = 'GET_ALL_PRODUCTS_FAILED';

export function getAllProducts(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_PRODUCTS
        })
        return new Promise((resolve, reject) => {
            getAllProductsAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_PRODUCTS_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_PRODUCTS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_SINGLE_PRODUCT_DETAILS = 'GET_SINGLE_PRODUCT_DETAILS';
export const GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL = 'GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL';
export const GET_SINGLE_PRODUCT_DETAILS_FAILED = 'GET_SINGLE_PRODUCT_DETAILS_FAILED';

export function getSingleProductDetails(data) {
    return dispatch => {
        dispatch({
            type: GET_SINGLE_PRODUCT_DETAILS
        })
        return new Promise((resolve, reject) => {
            getSingleProductDetailsAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_SINGLE_PRODUCT_DETAILS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_INFO_AND_EFFECT = 'GET_INFO_AND_EFFECT';
export const GET_INFO_AND_EFFECT_SUCCESSFULL = 'GET_INFO_AND_EFFECT_SUCCESSFULL';
export const GET_INFO_AND_EFFECT_FAILED = 'GET_INFO_AND_EFFECT_FAILED';

export function getInfoAndEffect(data) {
    return dispatch => {
        dispatch({
            type: GET_INFO_AND_EFFECT
        })
        return new Promise((resolve, reject) => {
            getInfoAndEffectAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_INFO_AND_EFFECT_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_INFO_AND_EFFECT_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT';
export const UPDATE_SINGLE_PRODUCT_SUCCESSFULL = 'UPDATE_SINGLE_PRODUCT_SUCCESSFULL';
export const UPDATE_SINGLE_PRODUCT_FAILED = 'UPDATE_SINGLE_PRODUCT_FAILED';

export function updateSingleProduct(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_SINGLE_PRODUCT
        })
        return new Promise((resolve, reject) => {
            updateSingleProductAPI(id, data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_SINGLE_PRODUCT_SUCCESSFULL,
                    })
                    return resolve(res)
                })
                .catch(err => {
                    toast.error(err)
                    dispatch({
                        type: UPDATE_SINGLE_PRODUCT_FAILED
                    })
                    return reject(err)
                })
        })
    }
}




export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESSFULL = 'ADD_CATEGORY_SUCCESSFULL';
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';

export function addCategory(data, id) {
    return dispatch => {
        dispatch({
            type: ADD_CATEGORY
        })
        return new Promise((resolve, reject) => {
            addCategoryAPI(data, id)
                .then((res) => {
                    dispatch({
                        type: ADD_CATEGORY_SUCCESSFULL,
                        data: res?.data
                    })
                    let txt = "Added"
                    if (id) {
                        txt = "Updated"
                    }
                    toast.success(`Category ${txt} successfully.`)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_CATEGORY_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY';
export const GET_ALL_CATEGORY_SUCCESSFULL = 'GET_ALL_CATEGORY_SUCCESSFULL';
export const GET_ALL_CATEGORY_FAILED = 'GET_ALL_CATEGORY_FAILED';

export function getAllCategory(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_CATEGORY
        })
        return new Promise((resolve, reject) => {
            getAllCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_CATEGORY_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_CATEGORY_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const GET_ALL_BLAZE_CATEGORY = 'GET_ALL_BLAZE_CATEGORY';
export const GET_ALL_BLAZE_CATEGORY_SUCCESSFULL = 'GET_ALL_BLAZE_CATEGORY_SUCCESSFULL';
export const GET_ALL_BLAZE_CATEGORY_FAILED = 'GET_ALL_BLAZE_CATEGORY_FAILED';

export function getAllBlazeCategory(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_BLAZE_CATEGORY
        })
        return new Promise((resolve, reject) => {
            getAllBlazeCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_BLAZE_CATEGORY_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_BLAZE_CATEGORY_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const CATEGORY_IMAGES = 'CATEGORY_IMAGES';
export const CATEGORY_IMAGES_SUCCESSFULL = 'CATEGORY_IMAGES_SUCCESSFULL';
export const CATEGORY_IMAGES_FAILED = 'CATEGORY_IMAGES_FAILED';

export function getAllCategoryImages(data) {
    return dispatch => {
        dispatch({
            type: CATEGORY_IMAGES
        })
        return new Promise((resolve, reject) => {
            getAllCategoryImagesAPI(data)
                .then((res) => {
                    dispatch({
                        type: CATEGORY_IMAGES_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: CATEGORY_IMAGES_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_SUCCESSFULL = 'UPDATE_CATEGORIES_SUCCESSFULL';
export const UPDATE_CATEGORIES_FAILED = 'UPDATE_CATEGORIES_FAILED';

export function updateCategories(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_CATEGORIES
        })
        return new Promise((resolve, reject) => {
            updateCategoriesAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_CATEGORIES_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_CATEGORIES_FAILED
                    })
                    toast.success(err || 'Something went wrong')
                    return reject(err)
                })
        })
    }
}

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESSFULL = 'DELETE_CATEGORY_SUCCESSFULL';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

export function deleteCategory(data) {
    return dispatch => {
        dispatch({
            type: DELETE_CATEGORY
        })
        return new Promise((resolve, reject) => {
            deleteCategoryAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_CATEGORY_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Category Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_CATEGORY_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ALL_BRANDS_BY_PRODUCT = 'ALL_BRANDS_BY_PRODUCT';
export const ALL_BRANDS_BY_PRODUCT_SUCCESSFULL = 'ALL_BRANDS_BY_PRODUCT_SUCCESSFULL';
export const ALL_BRANDS_BY_PRODUCT_FAILED = 'ALL_BRANDS_BY_PRODUCT_FAILED';

export function getAllBrandsByProducts(data) {
    return dispatch => {
        dispatch({
            type: ALL_BRANDS_BY_PRODUCT
        })
        return new Promise((resolve, reject) => {
            getAllBrandsByProductAPI(data)
                .then((res) => {
                    dispatch({
                        type: ALL_BRANDS_BY_PRODUCT_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ALL_BRANDS_BY_PRODUCT_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}
export const ADD_BRAND = 'ADD_BRAND';
export const ADD_BRAND_SUCCESSFULL = 'ADD_BRAND_SUCCESSFULL';
export const ADD_BRAND_FAILED = 'ADD_BRAND_FAILED';

export function addBrandToBmw(data) {
    return dispatch => {
        dispatch({
            type: ADD_BRAND
        })
        return new Promise((resolve, reject) => {
            addBrandToBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: ADD_BRAND_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success("Brand Added successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESSFULL = 'UPDATE_BRAND_SUCCESSFULL';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED';

export function updateBrandFromBmw(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_BRAND
        })
        return new Promise((resolve, reject) => {
            updateBrandFromBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPDATE_BRAND_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success("Brand Updated successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_SUCCESSFULL = 'DELETE_BRAND_SUCCESSFULL';
export const DELETE_BRAND_FAILED = 'DELETE_BRAND_FAILED';

export function deleteBrandFromBmw(data) {
    return dispatch => {
        dispatch({
            type: DELETE_BRAND
        })
        return new Promise((resolve, reject) => {
            deleteBrandFromBmwAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_BRAND_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success("Brand Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_BRAND_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

/**
 * Projects Actions
 */

export const ALL_PROJECTS = 'ALL_PROJECTS';
export const ALL_PROJECTS_SUCCESSFULL = 'ALL_PROJECTS_SUCCESSFULL';
export const ALL_PROJECTS_FAILED = 'ALL_PROJECTS_FAILED';

export function getAllProjects(data) {
    return dispatch => {
        dispatch({
            type: ALL_PROJECTS
        })
        return new Promise((resolve, reject) => {
            getAllProjectsAPI(data)
                .then((res) => {
                    dispatch({
                        type: ALL_PROJECTS_SUCCESSFULL,
                        data: {data: res?.data, total:res?.total}
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ALL_PROJECTS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_SUCCESSFULL = 'ADD_PROJECT_SUCCESSFULL';
export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED';

export function addProject(data) {
    return dispatch => {
        dispatch({
            type: ADD_PROJECT_FAILED
        })
        return new Promise((resolve, reject) => {
            addProjectAPI(data)
                .then((res) => {
                    dispatch({
                        type: ADD_PROJECT_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success("Project Added successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_PROJECT_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESSFULL = 'UPDATE_PROJECT_SUCCESSFULL';
export const UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED';

export function updateProject(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_PROJECT
        })
        return new Promise((resolve, reject) => {
            updateProjectAPI(id,data)
                .then(res => {
                    dispatch({
                        type: UPDATE_PROJECT_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_PROJECT_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}
  

// export const DELETE_PROJECT = 'DELETE_PROJECT';
// export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
// export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export function deleteProject(id) {
        return new Promise((resolve, reject) => {
            deleteProjectAPI(id)
                .then(res => {
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch((err => {
                    toast.error(err.message)
                    return reject(err)
                }))
        })
    }



export const UPLOAD_PROJECT_IMAGE = 'UPLOAD_PROJECT_IMAGE';
export const UPLOAD_PROJECT_IMAGE_SUCCESSFULL = 'UPLOAD_PROJECT_IMAGE_SUCCESSFULL';
export const UPLOAD_PROJECT_IMAGE_FAILED = 'UPLOAD_PROJECT_IMAGE_FAILED';

export function uploadProjectImage(data) {
    return dispatch => {
        dispatch({
            type: UPLOAD_PROJECT_IMAGE
        })
        return new Promise((resolve, reject) => {
            uploadProjectImageAPI(data)
                .then((res) => {
                    dispatch({
                        type: UPLOAD_PROJECT_IMAGE_SUCCESSFULL,
                        data: res?.data
                    })
                    // toast.success("Project updated successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPLOAD_PROJECT_IMAGE_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


