import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../../redux/actions/common"

import Dialog from './dialog';

import UserModal from '../../addUser';
import addProjectModal from '../../addProject';
import CustomConfirmationModal from '../CustomConfirmationModal';
import CustomerViewModal from './modal/CustomerViewModal';
import AddIntegrationModal from '../../../components/addIntegration/index';



export default function CustomModal() {
    const dispatch = useDispatch();
    const { modal } = useSelector(state => state.common);
    const closeModal = () => {
        dispatch(commonActions.closeCustomModalDialog({}))
    }
    switch (modal) {
        // case "EditProjectModal":
        //     return <RenderModalWithComponent maxWidth='md' component={editProjectModal} customFunction={closeModal} />
        
        case "AddUserModal":
            return <RenderModalWithComponent maxWidth='md' component={UserModal} customFunction={closeModal} />
        
        case "AddIntegrationModal":
            return <RenderModalWithComponent maxWidth='md' component={AddIntegrationModal} customFunction={closeModal} />
            
        case "AddProjectModal":
            return <RenderModalWithComponent component={addProjectModal} customFunction={closeModal} />
      
        case "ConfirmationModal":
            return <RenderModalWithComponent maxWidth='sm' component={CustomConfirmationModal} customFunction={closeModal}  />
      
        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "md", isCloseIcon, customFunction, ...rest }) => {
    return (
        <>
            <Dialog isCloseIcon={isCloseIcon} fullWidth maxWidth={maxWidth} customFunction={customFunction}>
                <Component {...rest} closeModal={customFunction} />
            </Dialog>
        </>
    )
}

