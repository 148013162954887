import { toast } from 'react-toastify';
import { getAllIntegrationsAPI,createIntegrationAPI,deleteIntegrationsAPI,uploadImageAPI,updateIntegrationAPI } from '../api/integrations';


export const GET_ALL_INTEGRATIONS = 'GET_ALL_INTEGRATIONS';
export const GET_ALL_INTEGRATIONS_SUCCESS = 'GET_ALL_INTEGRATIONS_SUCCESS';
export const GET_ALL_INTEGRATIONS_FAILURE = 'GET_ALL_INTEGRATIONS_FAILURE';

export function getAllIntegrations(data) {
    return dispatch => {
        dispatch ({
            type: GET_ALL_INTEGRATIONS,
        })
        return new Promise((resolve, reject) => {
                getAllIntegrationsAPI(data)
                .then(res => {
                    dispatch({
                        type: GET_ALL_INTEGRATIONS_SUCCESS,
                        data: {data:res?.data, total:res?.total}
                    })
                    return resolve(res)
                })
                .catch(err =>{
                    dispatch({
                        type:GET_ALL_INTEGRATIONS_FAILURE,
                        
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export const CREATE_INTERGRATIONS = 'CREATE_INTERGRATIONS';
export const CREATE_INTERGRATIONS_SUCCESS = 'CREATE_INTERGRATIONS_SUCCESS';
export const CREATE_INTERGRATIONS_FAILURE = 'CREATE_INTERGRATIONS_FAILURE';

export function createIntegrations(data){
    return dispatch =>{
       dispatch({
        type:CREATE_INTERGRATIONS
       })
       return new Promise((resolve, reject) => {
        createIntegrationAPI(data)
        .then(res=> {
            dispatch({
                type: CREATE_INTERGRATIONS_SUCCESS,
                data: res?.data
            })
            toast.success("Integration created successfully.")
            return resolve(res)
        })
        .catch(err =>{
            dispatch({
                type:CREATE_INTERGRATIONS_FAILURE,
                
            })
            toast.error(err)
            return reject(err)
            })
        })
    }
}

export const DELETE_INTERGRATIONS = 'DELETE_INTERGRATIONS';
export const DELETE_INTERGRATIONS_SUCCESS = 'DELETE_INTERGRATIONS_SUCCESS';
export const DELETE_INTERGRATIONS_FAILURE = 'DELETE_INTERGRATIONS_FAILURE';

export function deleteIntegrations(data){
    return dispatch =>{
       dispatch({
        type:DELETE_INTERGRATIONS
       })
       return new Promise((resolve, reject) => {
        deleteIntegrationsAPI(data)
        .then(res => {
            dispatch({
                type: DELETE_INTERGRATIONS_SUCCESS,
                data: res?.data
            })
            toast.success("Integration deleted successfully.")
            return resolve(res)
        })
        .catch(err =>{
            dispatch({
                type:DELETE_INTERGRATIONS_FAILURE,
             
            })
            toast.error(err)
            return reject(err)
            })
        })
    }
}

export const UPLOAD_INTERGRATIONS = 'UPLOAD_INTERGRATIONS';
export const UPLOAD_INTERGRATIONS_SUCCESS = 'UPLOAD_INTERGRATIONS_SUCCESS';
export const UPLOAD_INTERGRATIONS_FAILED = 'UPLOAD_INTERGRATIONS_FAILED';

export function uploadImage(data){
    return dispatch =>{
       dispatch({
        type:UPLOAD_INTERGRATIONS
       })
       return new Promise((resolve, reject) => {
        uploadImageAPI(data)
        .then(res => {
            dispatch({
                type: UPLOAD_INTERGRATIONS_SUCCESS,
                data: res?.data
            })
            return resolve(res)
        })
        .catch(err =>{
            dispatch({
                type:UPLOAD_INTERGRATIONS_FAILED,
             
            })
            toast.error(err)
            return reject(err)
            })
        })
    }
}


export const UPDATE_INTEGRATION = 'UPDATE_ADMIN';
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS'
export const UPDATE_INTEGRATION_FAILED = 'UPDATE_INTEGRATION_FAILED'

export function updateIntegration(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_INTEGRATION
        })
        return new Promise((resolve, reject) => {
            updateIntegrationAPI(id,data)
                .then(res => {
                    dispatch({
                        type: UPDATE_INTEGRATION_SUCCESS,
                        data: res?.data
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_INTEGRATION_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}
  