import { toast } from 'react-toastify';

import { getAllAdminAPI,addUserAPI, deleteUserAPI,updateUserAPI } from "../api/user";

export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCESS'
export const GET_ALL_ADMIN_FAILED = 'GET_ALL_ADMIN_FAILED'

export function getAllAdmin(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_ADMIN
        })
        return new Promise((resolve, reject) => {
            getAllAdminAPI(data)
                .then(res => {
                    dispatch({
                        type: GET_ALL_ADMIN_SUCCESS,
                        data: {data:res?.data, total:res?.total}
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_ADMIN_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_FAILED = 'ADD_ADMIN_FAILED'

export function addUser(id, data) { 
    return dispatch => { dispatch({ type: ADD_ADMIN }) 
    return new Promise((resolve, reject) => {
     addUserAPI(id, data) .then(res => { 
    dispatch({ type: ADD_ADMIN_SUCCESS, data: res?.data }) 
    if (id) { toast.success("Admin updated successfully.") } 
    else { toast.success("Admin created successfully.") } 
    return resolve(res) }) 
    .catch(err => { 
    dispatch({ type: ADD_ADMIN_FAILED }) 
    toast.error(err) 
    return reject(err) }) }) } }

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILED = 'DELETE_ADMIN_FAILED'

export function deleteUser(id) {
    return dispatch => {
        dispatch({ type: DELETE_ADMIN })
        return new Promise((resolve, reject) => {
            deleteUserAPI(id).then(res => {
                dispatch({ type: DELETE_ADMIN_SUCCESS, data: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: DELETE_ADMIN_FAILED })
                reject(err)
            })
        })
    }
}

export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_ADMIN_FAILED = 'UPDATE_ADMIN_FAILED'

export function updateUser(id, data) {
    return dispatch => {
        dispatch({
            type: UPDATE_ADMIN
        })
        return new Promise((resolve, reject) => {
            updateUserAPI(id,data)
                .then(res => {
                    dispatch({
                        type: UPDATE_ADMIN_SUCCESS,
                        data: res?.data
                    })
                    toast.success(res.message)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_ADMIN_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}
  