// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [

  {
    title: 'Project',
    path: '/dashboard/projects',
    icon: icon('Projects'),
  },
  {
    title: 'Admin',
    path: '/dashboard/admin',
    icon: icon('Admin'),
  },
  {
    title: 'Integrations',
    path: '/dashboard/integrations',
    icon: icon('Integration'),
  },


];

export default navConfig;