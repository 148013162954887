import React, { useEffect,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Button,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Pagination, Typography } from '@mui/material';


export const border = `1px solid black`;

function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function CustomTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableContainerHeight, setTableContainerHeight] = React.useState(0)
    const {
        tableHeading = [],
        rowsData = [],
        isLoading = false,
        showPagination = true,
        skip,
        currentPage,
        totalPages,
        handlePrevPage,
        handleNextPage,
        onChangeRowsPerPage,
        limit,
        onChangePagination,
        onReset  
    } = props;

        console.log("props",props);
    const tableContainerRef = useRef(null);

    useEffect(() => {
        const height = tableContainerRef.current?.getBoundingClientRect()?.height;
        setTableContainerHeight(height || 0);
        setPage(0);
        if (typeof onReset === 'function') {      
            onReset();                    
        }
    }, [rowsData, skip]);

    const handleChangePage = (event, newPage) => {
        if ((newPage - 1) !== currentPage) {
            if ((newPage - 1) > currentPage) {
                newPage = newPage > 1 ? newPage - 1 : 0
                handleNextPage(newPage);    
            }
            if ((newPage - 1) < currentPage) {
                newPage = newPage > 1 ? newPage - 1 : 0
                handlePrevPage(newPage);
            }
            setPage(newPage - 1);
        }
    };


    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = event?.target?.value ?? 10;
        setRowsPerPage(newRowsPerPage);
        onChangeRowsPerPage(newRowsPerPage);
        setPage(1)
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rowsData.length - page * rowsPerPage);

    const useStyles = makeStyles((theme) => ({
        root: {
            "& > *": {
                marginTop: theme.spacing(2)
            },
            "& .MuiPaginationItem-icon": {
                backgroundColor: "#C2ABFA"
            },
            table: {
                minWidth: 650,
            },
            
            customTableContainer: {
                overflowX: "initial"
              }

            }   
        
    }));

    const classes = useStyles();
    const numColumns = tableHeading.length; 

    return (
        <div className="cus-table">
        <TableContainer
            classes={{ root: classes.customTableContainer, height: tableContainerHeight }}
            ref={tableContainerRef}
            component={Paper}
       
        >
                <Table stickyHeader>
                    
                    <TableHead>
                        <TableRow>
                            {tableHeading.map((heading, index) => {
                                return (
                                    <TableCell
                                        key={index}
                                        className={heading.key === 'actions' ? 'more' : ''} 
                                    >
                                        {heading?.name || '-'}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {isLoading ? (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan="6">
                                    <span className="table-loading">
                                        <CircularProgress />
                                    </span>
                                </TableCell>
                            </TableRow>
                        ) : rowsData && rowsData.length ? (
                            stableSort(rowsData)
                                .slice(page * limit, page * limit + limit)
                                .map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            {tableHeading?.map((heading, _index) => {
                                                return (
                                                    <TableCell className={heading.key==='actions' ? "more" : "table-custom-width"}  key={_index}>
                                                        {row?.[heading.key] || '-'}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                        ) 
                        : (
                            <TableRow>
                                <TableCell colSpan={tableHeading.length} align="center">
                                    <div className="no-data txt-center">
                                        <img src="/assets/NoOrdersFound.png" alt="" />
                                        <Typography variant="h5">No data found</Typography>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {/* {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={numColumns} />
                            </TableRow>
                            
                        )} */}
                       
                    </TableBody>
                
                </Table>
            </TableContainer>
            {showPagination && rowsData && rowsData.length > 0 && (
                <div className='cus-table-pagination-list'>
                    <Pagination
                        count={totalPages}
                        page={currentPage + 1}
                        onChange={(event, newPage) => handleChangePage(event, newPage)}
                        color="primary"
                        shape="rounded"
                        size="large"
                        siblingCount={1}
                        boundaryCount={1}
                        rowsPerPage={limit}
                        className="cus-table-pagination"
                    />
                    <div className='pagention-ul'>
                        <span>Rows Per Page &nbsp;</span>
                        <Select value={limit} onChange={handleChangeRowsPerPage}>
                            {[10, 20, 30].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            )}
        </div>
    );
}