
import {
    ADD_CATEGORY,
    ADD_CATEGORY_FAILED,
    ADD_CATEGORY_SUCCESSFULL,
    GET_ALL_BLAZE_CATEGORY,
    GET_ALL_BLAZE_CATEGORY_FAILED,
    GET_ALL_BLAZE_CATEGORY_SUCCESSFULL,
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_FAILED,
    GET_ALL_CATEGORY_SUCCESSFULL,
    GET_ALL_PRODUCTS,
    GET_ALL_PRODUCTS_FAILED,
    GET_ALL_PRODUCTS_SUCCESSFULL,
    GET_INFO_AND_EFFECT,
    GET_INFO_AND_EFFECT_FAILED,
    GET_INFO_AND_EFFECT_SUCCESSFULL,
    GET_SINGLE_PRODUCT_DETAILS,
    GET_SINGLE_PRODUCT_DETAILS_FAILED,
    GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL,
    UPDATE_CATEGORIES,
    UPDATE_CATEGORIES_FAILED,
    UPDATE_CATEGORIES_SUCCESSFULL,
    UPDATE_SINGLE_PRODUCT,
    UPDATE_SINGLE_PRODUCT_FAILED,
    UPDATE_SINGLE_PRODUCT_SUCCESSFULL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESSFULL,
    DELETE_CATEGORY_FAILED,
    CATEGORY_IMAGES_SUCCESSFULL,
    CATEGORY_IMAGES_FAILED,
    ALL_BRANDS_BY_PRODUCT,
    ALL_BRANDS_BY_PRODUCT_SUCCESSFULL,
    ALL_BRANDS_BY_PRODUCT_FAILED,
    UPDATE_PROJECT,
    UPDATE_PROJECT_SUCCESSFULL,
    UPDATE_PROJECT_FAILED,
    ALL_PROJECTS,
    ALL_PROJECTS_SUCCESSFULL,
    ALL_PROJECTS_FAILED,
  
  
} from '../actions/products';



const initialState = {
    isLoading: false,
    allProjects:{},

    allProducts: {},
    singleProductDetailData: {},
    infoAndEffectsData: [],
    productThreshold:0,   
    allCategoryData: {},
    allBlazeCategoryData: {},
    categoryImages: [],
    allBrands: {},
    allBmwBrands: {},
    syncProductLoader: false,
    
}

export default function product(state = { ...initialState }, action = {}) {
    switch (action.type) {

        
        case GET_ALL_PRODUCTS: 
            return { ...state, isLoading: true }

        case GET_ALL_PRODUCTS_SUCCESSFULL:
            return { ...state, isLoading: false, allProducts: action.data }

        case GET_ALL_PRODUCTS_FAILED:
            return { ...state, isLoading: false }

        
        case GET_SINGLE_PRODUCT_DETAILS:
            return { ...state, isLoading: true }

        case GET_SINGLE_PRODUCT_DETAILS_SUCCESSFULL:
            return { ...state, isLoading: false, singleProductDetailData: action.data }

        case GET_SINGLE_PRODUCT_DETAILS_FAILED:
            return { ...state, isLoading: false }

  
        case GET_INFO_AND_EFFECT:
            return { ...state, isLoading: true }

        case GET_INFO_AND_EFFECT_SUCCESSFULL:
            return { ...state, isLoading: false, infoAndEffectsData: action.data }

        case GET_INFO_AND_EFFECT_FAILED:
            return { ...state, isLoading: false }

  
        case UPDATE_SINGLE_PRODUCT:
            return { ...state, isLoading: true }

        case UPDATE_SINGLE_PRODUCT_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_SINGLE_PRODUCT_FAILED:
            return { ...state, isLoading: false }

      
        case ADD_CATEGORY:
            return { ...state, isLoading: true }

        case ADD_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false }

        case ADD_CATEGORY_FAILED:
            return { ...state, isLoading: false }

       
        case GET_ALL_CATEGORY:
            return { ...state, isLoading: true }

        case GET_ALL_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false, allCategoryData: action.data }

        case GET_ALL_CATEGORY_FAILED:
            return { ...state, isLoading: false }

     

        case UPDATE_CATEGORIES:
            return { ...state, isLoading: true }

        case UPDATE_CATEGORIES_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_CATEGORIES_FAILED:
            return { ...state, isLoading: false }

       
        case DELETE_CATEGORY:
            return { ...state, isLoading: true }

        case DELETE_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false }

        case DELETE_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        
        case CATEGORY_IMAGES_SUCCESSFULL:
            return { ...state, isLoading: false, categoryImages: action.data }

        case CATEGORY_IMAGES_FAILED:
            return { ...state, isLoading: false, categoryImages: action.data }

   
        case GET_ALL_BLAZE_CATEGORY:
            return { ...state, isLoading: true }

        case GET_ALL_BLAZE_CATEGORY_SUCCESSFULL:
            return { ...state, isLoading: false, allBlazeCategoryData: action.data }

        case GET_ALL_BLAZE_CATEGORY_FAILED:
            return { ...state, isLoading: false }

        case ALL_BRANDS_BY_PRODUCT:
            return { ...state, isLoading: true }

        case ALL_BRANDS_BY_PRODUCT_SUCCESSFULL:
            return { ...state, isLoading: false, allBrands: action.data }

        case ALL_BRANDS_BY_PRODUCT_FAILED:
            return { ...state, isLoading: false }

        
        case ALL_PROJECTS:
            return { ...state, isLoading: true }

        case ALL_PROJECTS_SUCCESSFULL:
            return { ...state, isLoading: false, allProjects: {...action.data} }

        case ALL_PROJECTS_FAILED:
            return { ...state, isLoading: false }
        
        case UPDATE_PROJECT:
            return { ...state, isLoading: true }

        case UPDATE_PROJECT_SUCCESSFULL:
            return { ...state, isLoading: false }

        case UPDATE_PROJECT_FAILED:
            return { ...state, isLoading: false }

    

        default:
            return state
    }
}