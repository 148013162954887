import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { useDispatch } from 'react-redux';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { logoutUser } from '../../redux/actions/auth';
import * as commonActions from "../../redux/actions/common";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};



export default function NavSection({ data = [], ...other }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  

  const handleConfirmation = () => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to logout?",
      confirm: () => { handleLogout() },
      cancel: () => { }
    }, "ConfirmationModal"))

  }

  const handleLogout = () => {
    dispatch(logoutUser())
    navigate("/login")
    // setOpen(null);
  };
  return (
    <Box {...other}>
      <List className='nav-list' disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => (
          <li key={item.id ?? index}>
            <NavItem item={item} />
          </li>
        ))}
      </List>
      <ul className='logout'>
        <li  onClick={handleConfirmation} className='d-flex ' > 
        <svg width={24} className="MuiSvgIcon-root logout-icon mr-1" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" fill='white'/>
          </svg> 
            Logout</li>
      </ul>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem component={RouterLink}  to={path}  sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}